.Info{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.right-i{
    flex: 1 1;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-i>span{
    color: salmon;
    font-size: 1.9rem;
    font-weight: bold;
    font-style: italic;
}

.details-i{
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
}

.details-i>div{
    display: flex;
    font-size: 1.15rem;
    gap: 1rem;
    color: white;
    text-indent: 1.75rem;
}

.height-container{
    display: flex;
    gap: 3rem;
    padding-left: 2rem;
}

.weight-container{
    display: flex;
    gap: 3rem;
    padding-left: 1.75rem;
}

.age-container{
    display: flex;
    gap: 3rem;
    padding-left: 3.95rem;
}

.btn-cut{
    background-color: maroon;
    color: white;
}

.btn-main{
    background-color: gray;
    color: white;
}

.btn-bulk{
    background-color: darkgreen;
    color: white;
}

.btn-join{
    background-color: white;
    width: 25rem;
    color: black;
}

.join-i{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.join-i>div{
    display: flex;
    font-size: 1.15rem;
    gap: 1rem;
    color: white;
    padding-left: 1rem;
}

.macrocenter{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.macros{
    font-size: 1.9rem;
    font-weight: bold;
    font-style: italic;
    color: salmon;
    text-transform: uppercase;
    text-align: center;
}

.cpc{
    color: white;
    font-size: 1.15;
    text-transform: uppercase;
    text-indent: 3rem;
}

.cal{
    color: salmon;
    text-indent: 2rem;
    padding-left: 3rem;
    font-size: 1.25rem;
}

.kcal{
    text-transform: none;
}

.protein{
    color: salmon;
    text-indent: 2rem;
    padding-left: 3.5rem;
    font-size: 1.25rem;
}

.carbs{
    color: salmon;
    text-indent: 2rem;
    padding-left: 4.4rem;
    font-size: 1.25rem;
}

.fats{
    color: salmon;
    text-indent: 2rem;
    padding-left: 5.5rem;
    font-size: 1.25rem;
}

.end{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fiber{
    color: salmon;
    text-indent: 3rem;
    padding-left: 5rem;
    font-size: 1.25rem;
}

.fat{
    color: salmon;
    text-indent: 3rem;
    padding-left: 6rem;
    font-size: 1.25rem;
}

.sodium{
    color: salmon;
    text-indent: 3rem;
    padding-left: 3.6rem;
    font-size: 1.25rem;
}

.potassium{
    color: salmon;
    text-indent: 3rem;
    padding-left: 1.8rem;
    font-size: 1.25rem;
}

.details-i>div>span:nth-of-type(2){
    text-indent: -0.5rem;
    font-size: 1rem;
}

.gender>button:nth-of-type(1){
    position: absolute;
    left: 13rem;
    top: 102.85rem;
}

.gender>button:nth-of-type(2){
    position: absolute;
    left: 17.85rem;
    top: 102.85rem;
}

.btn-male{
    width: 3rem;
    height: 2.5rem;
    background-color: lightskyblue;
}

.btn-fem{
    width: 3rem;
    height: 2.5rem;
    background-color: lightpink;
}

.btn-male>img{
    width: 2rem;
}

.btn-fem>img{
    width: 2rem;
}

@media screen and (max-width: 768px){
    .macros{
        font-size:1.3rem;
    }

    .Info{
        flex-direction: column;
    }

    .right-i>span{
        font-size:1.3rem;
    }

    .details-i{
        display: flex;
        flex-direction: column;
        gap: 1.1rem;
    }

    .details-i>div{
        display: flex;
        font-size: 0.75rem;
        gap: 0.5rem;
        color: white;
        text-indent: -1rem;
        padding-top: 0.25rem;
    }

    .height-container{
        display: flex;
        gap: 3rem;
        padding-left: 1rem;
    }

    .weight-container{
        display: flex;
        gap: 3rem;
        padding-left: 0.85rem;
    }
    
    .age-container{
        display: flex;
        gap: 3rem;
        padding-left: 2.25rem;
    }

    .details-i>div>span:nth-of-type(2){
        text-indent: -0.2rem;
        font-size:0.75rem;
        justify-content: center;
        padding-top: 0.25rem;
    }

    .gender>button:nth-of-type(1){
        position: absolute;
        left: 7.7rem;
        top: 128.6rem;
    }
    
    .gender>button:nth-of-type(2){
        position:absolute;
        left: 12rem;
        top: 128.6rem;

    }
    
    .btn-male{
        width: 2.5rem;
        height: 2.5rem;
        background-color: lightskyblue;
    }
    
    .btn-fem{
        width: 2.5rem;
        height: 2.5rem;
        background-color: lightpink;
    }
    
    .btn-male>img{
        width: 2rem;
    }
    
    .btn-fem>img{
        width: 2rem;
    }

    .btn-join{
        width:35rem;
    }

}
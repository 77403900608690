.header{
    display: flex;
    justify-content: space-between;
}

.logo>span{
    width: 35rem;
    height: 2rem;
    color: white;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    -webkit-text-stroke-width: 2px;
    font-size: 55px;
}

.logo>span:nth-of-type(1){
    color: salmon;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2.5rem;
    color:white;
}

.header-menu>li:hover{
    cursor: pointer;
    color: salmon;
}

@media screen and (max-width: 768px){
    .header>:nth-child(2){
        position: fixed;
        top: 5rem;
        right: 2rem;
        z-index: 99;
    }

    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }

    .logo>span{
        width: 25rem;
        height: 2rem;
        color: white;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        -webkit-text-stroke-width: 2px;
        font-size: 35px;
    }
}
